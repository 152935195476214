import Hinemoa1 from "../assets/imgs/Projects/Hinemoa/1.jpeg";
import Hinemoa2 from "../assets/imgs/Projects/Hinemoa/2.jpeg";
import Hinemoa3 from "../assets/imgs/Projects/Hinemoa/3.jpeg";
import Hinemoa4 from "../assets/imgs/Projects/Hinemoa/4.jpeg";
import Hinemoa5 from "../assets/imgs/Projects/Hinemoa/5.jpeg";
import Hinemoa6 from "../assets/imgs/Projects/Hinemoa/6.jpeg";
import Hinemoa7 from "../assets/imgs/Projects/Hinemoa/7.jpeg";
import Hinemoa8 from "../assets/imgs/Projects/Hinemoa/8.jpeg";
import Hinemoa9 from "../assets/imgs/Projects/Hinemoa/9.jpeg";
import Hinemoa10 from "../assets/imgs/Projects/Hinemoa/10.jpeg";

import Ely1 from "../assets/imgs/Projects/Ely/1.jpeg";
import Ely2 from "../assets/imgs/Projects/Ely/2.jpeg";
import Ely3 from "../assets/imgs/Projects/Ely/3.jpeg";
import Ely4 from "../assets/imgs/Projects/Ely/4.jpeg";
import Ely5 from "../assets/imgs/Projects/Ely/5.jpeg";
import Ely6 from "../assets/imgs/Projects/Ely/6.jpeg";
import Ely7 from "../assets/imgs/Projects/Ely/7.jpeg";
import Ely8 from "../assets/imgs/Projects/Ely/8.jpeg";
import Ely9 from "../assets/imgs/Projects/Ely/9.jpeg";
import Ely10 from "../assets/imgs/Projects/Ely/10.jpeg";

import Dove1 from "../assets/imgs/Projects/Dove/1.jpeg";
import Dove2 from "../assets/imgs/Projects/Dove/2.jpeg";
import Dove3 from "../assets/imgs/Projects/Dove/3.jpeg";
import Dove4 from "../assets/imgs/Projects/Dove/4.jpeg";
import Dove5 from "../assets/imgs/Projects/Dove/5.jpeg";
import Dove6 from "../assets/imgs/Projects/Dove/6.jpeg";
import Dove7 from "../assets/imgs/Projects/Dove/7.jpeg";
import Dove8 from "../assets/imgs/Projects/Dove/8.jpeg";
import Dove9 from "../assets/imgs/Projects/Dove/9.jpeg";
import Dove10 from "../assets/imgs/Projects/Dove/10.jpeg";
import Dove11 from "../assets/imgs/Projects/Dove/11.jpeg";
import Dove12 from "../assets/imgs/Projects/Dove/12.jpeg";

import Thomas1 from "../assets/imgs/Projects/Thomas/1.jpeg";
import Thomas2 from "../assets/imgs/Projects/Thomas/2.jpeg";
import Thomas3 from "../assets/imgs/Projects/Thomas/3.jpeg";
import Thomas4 from "../assets/imgs/Projects/Thomas/4.jpeg";
import Thomas5 from "../assets/imgs/Projects/Thomas/5.jpeg";
import Thomas6 from "../assets/imgs/Projects/Thomas/6.jpeg";
import Thomas7 from "../assets/imgs/Projects/Thomas/7.jpeg";
import Thomas8 from "../assets/imgs/Projects/Thomas/8.jpeg";
import Thomas9 from "../assets/imgs/Projects/Thomas/9.jpeg";
import Thomas10 from "../assets/imgs/Projects/Thomas/10.jpeg";
import Thomas11 from "../assets/imgs/Projects/Thomas/11.jpeg";
import Thomas12 from "../assets/imgs/Projects/Thomas/12.jpeg";
import Thomas13 from "../assets/imgs/Projects/Thomas/13.jpeg";
import Thomas14 from "../assets/imgs/Projects/Thomas/14.jpeg";
import Thomas15 from "../assets/imgs/Projects/Thomas/15.jpeg";

import Anne1 from "../assets/imgs/Projects/Anne/1.webp";
import Anne2 from "../assets/imgs/Projects/Anne/2.jpg";
import Anne3 from "../assets/imgs/Projects/Anne/3.jpg";
import Anne4 from "../assets/imgs/Projects/Anne/4.jpg";
import Anne5 from "../assets/imgs/Projects/Anne/5.jpg";
import Anne6 from "../assets/imgs/Projects/Anne/6.jpg";
import Anne7 from "../assets/imgs/Projects/Anne/7.jpg";
import Anne8 from "../assets/imgs/Projects/Anne/8.jpg";
import Anne9 from "../assets/imgs/Projects/Anne/9.jpg";
import Anne10 from "../assets/imgs/Projects/Anne/10.jpg";
import Anne11 from "../assets/imgs/Projects/Anne/11.jpg";
import Anne12 from "../assets/imgs/Projects/Anne/12.jpg";
import Anne13 from "../assets/imgs/Projects/Anne/13.jpg";


import sherwood1 from "../assets/imgs/Projects/sherwood/1.jpg";
import sherwood2 from "../assets/imgs/Projects/sherwood/2.jpg";
import sherwood3 from "../assets/imgs/Projects/sherwood/3.jpg";
import sherwood4 from "../assets/imgs/Projects/sherwood/4.jpg";
import sherwood5 from "../assets/imgs/Projects/sherwood/5.jpg";
import sherwood6 from "../assets/imgs/Projects/sherwood/6.jpg";
import sherwood7 from "../assets/imgs/Projects/sherwood/7.jpg";
import sherwood8 from "../assets/imgs/Projects/sherwood/8.jpg";
import sherwood9 from "../assets/imgs/Projects/sherwood/9.jpg";
import sherwood10 from "../assets/imgs/Projects/sherwood/10.jpg";
import sherwood11 from "../assets/imgs/Projects/sherwood/11.jpg";
import sherwood12 from "../assets/imgs/Projects/sherwood/12.jpg";
import sherwood13 from "../assets/imgs/Projects/sherwood/13.jpg";
import sherwood14 from "../assets/imgs/Projects/sherwood/14.jpg";
import sherwood15 from "../assets/imgs/Projects/sherwood/15.jpg";
import sherwood16 from "../assets/imgs/Projects/sherwood/16.jpg";
import sherwood17 from "../assets/imgs/Projects/sherwood/17.jpg";
import sherwood18 from "../assets/imgs/Projects/sherwood/18.jpg";
import sherwood19 from "../assets/imgs/Projects/sherwood/19.jpg";

import Gwandalan1 from "../assets/imgs/Projects/Gwandalan/1.jpg";
import Gwandalan2 from "../assets/imgs/Projects/Gwandalan/2.jpg";
import Gwandalan3 from "../assets/imgs/Projects/Gwandalan/3.jpg";
import Gwandalan4 from "../assets/imgs/Projects/Gwandalan/4.jpg";
import Gwandalan5 from "../assets/imgs/Projects/Gwandalan/5.jpg";
import Gwandalan6 from "../assets/imgs/Projects/Gwandalan/6.jpg";
import Gwandalan7 from "../assets/imgs/Projects/Gwandalan/7.jpg";
import Gwandalan8 from "../assets/imgs/Projects/Gwandalan/8.jpg";
import Gwandalan9 from "../assets/imgs/Projects/Gwandalan/9.jpg";
import Gwandalan10 from "../assets/imgs/Projects/Gwandalan/10.jpg";
import Gwandalan11 from "../assets/imgs/Projects/Gwandalan/11.jpg";
import Gwandalan12 from "../assets/imgs/Projects/Gwandalan/12.jpg";
import Gwandalan13 from "../assets/imgs/Projects/Gwandalan/13.jpg";
import Gwandalan14 from "../assets/imgs/Projects/Gwandalan/14.jpg";
import Gwandalan15 from "../assets/imgs/Projects/Gwandalan/15.jpg";
import Gwandalan16 from "../assets/imgs/Projects/Gwandalan/16.jpg";
import Gwandalan17 from "../assets/imgs/Projects/Gwandalan/17.jpg";
import Gwandalan18 from "../assets/imgs/Projects/Gwandalan/18.jpg";
import Gwandalan19 from "../assets/imgs/Projects/Gwandalan/19.jpg";


import seaforth1 from "../assets/imgs/Projects/Seaforth/1.jpg";
import seaforth2 from "../assets/imgs/Projects/Seaforth/2.jpg";
import seaforth3 from "../assets/imgs/Projects/Seaforth/3.jpg";
import seaforth4 from "../assets/imgs/Projects/Seaforth/4.jpg";
import seaforth5 from "../assets/imgs/Projects/Seaforth/5.jpg";
import seaforth6 from "../assets/imgs/Projects/Seaforth/6.jpg";
import seaforth7 from "../assets/imgs/Projects/Seaforth/7.jpg";
import seaforth8 from "../assets/imgs/Projects/Seaforth/8.jpg";
import seaforth9 from "../assets/imgs/Projects/Seaforth/9.jpg";
import seaforth10 from "../assets/imgs/Projects/Seaforth/10.jpg";
import seaforth11 from "../assets/imgs/Projects/Seaforth/11.jpg";


import Atkinson1 from "../assets/imgs/Projects/Atkinson/1.webp";
import Atkinson2 from "../assets/imgs/Projects/Atkinson/2.jpg";
import Atkinson3 from "../assets/imgs/Projects/Atkinson/3.jpg";
import Atkinson4 from "../assets/imgs/Projects/Atkinson/4.jpg";
import Atkinson5 from "../assets/imgs/Projects/Atkinson/5.jpg";
import Atkinson6 from "../assets/imgs/Projects/Atkinson/6.jpg";


import Wallis1 from "../assets/imgs/Projects/Wallis/1.jpg";
import Wallis2 from "../assets/imgs/Projects/Wallis/2.jpg";
import Wallis3 from "../assets/imgs/Projects/Wallis/3.jpg";
import Wallis4 from "../assets/imgs/Projects/Wallis/4.jpg";
import Wallis5 from "../assets/imgs/Projects/Wallis/5.jpg";
import Wallis6 from "../assets/imgs/Projects/Wallis/6.jpg";
import Wallis7 from "../assets/imgs/Projects/Wallis/7.jpg";
import Wallis8 from "../assets/imgs/Projects/Wallis/8.jpg";




import Ellesmere1 from "../assets/imgs/Projects/Ellesmere/1.webp";
import Ellesmere2 from "../assets/imgs/Projects/Ellesmere/2.jpg";
import Ellesmere3 from "../assets/imgs/Projects/Ellesmere/3.jpg";
import Ellesmere4 from "../assets/imgs/Projects/Ellesmere/4.jpg";
import Ellesmere5 from "../assets/imgs/Projects/Ellesmere/5.jpg";
import Ellesmere6 from "../assets/imgs/Projects/Ellesmere/6.jpg";
import Ellesmere7 from "../assets/imgs/Projects/Ellesmere/7.jpg";
import Ellesmere8 from "../assets/imgs/Projects/Ellesmere/8.jpg";
import Ellesmere9 from "../assets/imgs/Projects/Ellesmere/9.jpg";
import Ellesmere10 from "../assets/imgs/Projects/Ellesmere/10.jpg";


import ParkRoad1 from "../assets/imgs/Projects/ParkRoad/1.jpg";
import ParkRoad2 from "../assets/imgs/Projects/ParkRoad/2.jpg";
import ParkRoad3 from "../assets/imgs/Projects/ParkRoad/3.jpg";
import ParkRoad4 from "../assets/imgs/Projects/ParkRoad/4.jpg";
import ParkRoad5 from "../assets/imgs/Projects/ParkRoad/5.jpg";
import ParkRoad6 from "../assets/imgs/Projects/ParkRoad/6.jpg";
import ParkRoad7 from "../assets/imgs/Projects/ParkRoad/7.jpg";
import ParkRoad8 from "../assets/imgs/Projects/ParkRoad/8.jpg";
import ParkRoad9 from "../assets/imgs/Projects/ParkRoad/9.jpg";
import ParkRoad10 from "../assets/imgs/Projects/ParkRoad/10.jpg";
import ParkRoad11 from "../assets/imgs/Projects/ParkRoad/11.jpg";
import ParkRoad12 from "../assets/imgs/Projects/ParkRoad/12.jpg";
import ParkRoad13 from "../assets/imgs/Projects/ParkRoad/13.jpg";
import ParkRoad14 from "../assets/imgs/Projects/ParkRoad/14.jpg";
import ParkRoad15 from "../assets/imgs/Projects/ParkRoad/15.jpg";
import ParkRoad16 from "../assets/imgs/Projects/ParkRoad/16.jpg";
import ParkRoad17 from "../assets/imgs/Projects/ParkRoad/17.jpg";
import ParkRoad18 from "../assets/imgs/Projects/ParkRoad/18.jpg";

import PicnicPointRoad1 from "../assets/imgs/Projects/PicnicPointRoad/1.jpg";
import PicnicPointRoad2 from "../assets/imgs/Projects/PicnicPointRoad/2.jpg";
import PicnicPointRoad3 from "../assets/imgs/Projects/PicnicPointRoad/3.jpg";
import PicnicPointRoad4 from "../assets/imgs/Projects/PicnicPointRoad/4.jpg";
import PicnicPointRoad5 from "../assets/imgs/Projects/PicnicPointRoad/5.jpg";
import PicnicPointRoad6 from "../assets/imgs/Projects/PicnicPointRoad/6.jpg";
import PicnicPointRoad7 from "../assets/imgs/Projects/PicnicPointRoad/7.jpg";
import PicnicPointRoad8 from "../assets/imgs/Projects/PicnicPointRoad/8.jpg";
import PicnicPointRoad9 from "../assets/imgs/Projects/PicnicPointRoad/9.jpg";
import PicnicPointRoad10 from "../assets/imgs/Projects/PicnicPointRoad/10.jpg";
import PicnicPointRoad11 from "../assets/imgs/Projects/PicnicPointRoad/11.jpg";
import PicnicPointRoad12 from "../assets/imgs/Projects/PicnicPointRoad/12.jpg";
import PicnicPointRoad13 from "../assets/imgs/Projects/PicnicPointRoad/13.jpg";
import PicnicPointRoad14 from "../assets/imgs/Projects/PicnicPointRoad/14.jpg";
import PicnicPointRoad15 from "../assets/imgs/Projects/PicnicPointRoad/15.jpg";
import PicnicPointRoad16 from "../assets/imgs/Projects/PicnicPointRoad/16.jpg";
import PicnicPointRoad17 from "../assets/imgs/Projects/PicnicPointRoad/17.jpg";
import PicnicPointRoad18 from "../assets/imgs/Projects/PicnicPointRoad/18.jpg";
import PicnicPointRoad19 from "../assets/imgs/Projects/PicnicPointRoad/19.jpg";
import PicnicPointRoad20 from "../assets/imgs/Projects/PicnicPointRoad/20.jpg";
import PicnicPointRoad21 from "../assets/imgs/Projects/PicnicPointRoad/21.jpg";
import PicnicPointRoad22 from "../assets/imgs/Projects/PicnicPointRoad/22.jpg";
import PicnicPointRoad23 from "../assets/imgs/Projects/PicnicPointRoad/23.jpg";
import PicnicPointRoad24 from "../assets/imgs/Projects/PicnicPointRoad/24.jpg";
import PicnicPointRoad25 from "../assets/imgs/Projects/PicnicPointRoad/25.jpg";
import PicnicPointRoad26 from "../assets/imgs/Projects/PicnicPointRoad/26.jpg";

import Barrow1 from "../assets/imgs/Projects/Barrow/1.jpg";
import Barrow2 from "../assets/imgs/Projects/Barrow/2.jpg";
import Barrow3 from "../assets/imgs/Projects/Barrow/3.jpg";
import Barrow4 from "../assets/imgs/Projects/Barrow/4.jpg";
import Barrow5 from "../assets/imgs/Projects/Barrow/5.jpg";
import Barrow6 from "../assets/imgs/Projects/Barrow/6.jpg";
import Barrow7 from "../assets/imgs/Projects/Barrow/7.jpg";
import Barrow8 from "../assets/imgs/Projects/Barrow/8.jpg";
import Barrow9 from "../assets/imgs/Projects/Barrow/9.jpg";
import Barrow10 from "../assets/imgs/Projects/Barrow/10.jpg";
import Barrow11 from "../assets/imgs/Projects/Barrow/11.jpg";
import Barrow12 from "../assets/imgs/Projects/Barrow/12.jpg";
import Barrow13 from "../assets/imgs/Projects/Barrow/13.jpg";
import Barrow14 from "../assets/imgs/Projects/Barrow/14.jpg";
import Barrow15 from "../assets/imgs/Projects/Barrow/15.jpg";
import Barrow16 from "../assets/imgs/Projects/Barrow/16.jpg";
import Barrow17 from "../assets/imgs/Projects/Barrow/17.jpg";
import Barrow18 from "../assets/imgs/Projects/Barrow/18.jpg";
import Barrow19 from "../assets/imgs/Projects/Barrow/19.jpg";
import Barrow20 from "../assets/imgs/Projects/Barrow/20.jpg";
import Barrow21 from "../assets/imgs/Projects/Barrow/21.jpg";
import Barrow22 from "../assets/imgs/Projects/Barrow/22.jpg";
import Barrow23 from "../assets/imgs/Projects/Barrow/23.jpg";
import Barrow24 from "../assets/imgs/Projects/Barrow/24.jpg";
import Barrow25 from "../assets/imgs/Projects/Barrow/25.jpg";
import Barrow26 from "../assets/imgs/Projects/Barrow/26.jpg";
import Barrow27 from "../assets/imgs/Projects/Barrow/27.jpg";
import Barrow28 from "../assets/imgs/Projects/Barrow/28.jpg";
import Barrow29 from "../assets/imgs/Projects/Barrow/29.jpg";
import Barrow30 from "../assets/imgs/Projects/Barrow/30.jpg";

export const projects = [
  {
    id: 0.1,
    name: "Hinemoa",
    mainImg: Hinemoa7,
    altText: "Hinemoa",
    images: [
      {
        id: 1,
        url: Hinemoa7,
        altText: "Hinemoa-Front View",
      },
      {
        id: 2,
        url: Hinemoa2,
        altText: "Hinemoa-Bathroom Design",
      },
      {
        id: 3,
        url: Hinemoa3,
        altText: "Hinemoa-Bedroom Design",
      },
      {
        id: 4,
        url: Hinemoa4,
        altText: "Hinemoa-Back View from Sitting Room ",
      },
      {
        id: 5,
        url: Hinemoa5,
        altText: "Hinemoa-Bedroom Design ",
      },
      {
        id: 6,
        url: Hinemoa6,
        altText: "Hinemoa-Counter Top ",
      },
      {
        id: 7,
        url: Hinemoa1,
        altText: "Hinemoa-TV Launge ",
      },
      {
        id: 8,
        url: Hinemoa8,
        altText: "Hinemoa-TV Launge ",
      },
      {
        id: 9,
        url: Hinemoa9,
        altText: "Hinemoa-Bedroom Design ",
      },
      {
        id: 10,
        url: Hinemoa10,
        altText: "Hinemoa-Backside Gallery ",
      },
    ],
  },
  {
    id: 0.2,
    name: "Dove",
    mainImg: Dove1,
    altText: "Dove",
    images: [
      {
        id: 1,
        url: Dove1,
        altText: "Dove-Front View",
      },
      {
        id: 2,
        url: Dove2,
        altText: "Dove-Backyard View",
      },
      {
        id: 3,
        url: Dove3,
        altText: "Dove-Backside View",
      },
      {
        id: 4,
        url: Dove4,
        altText: "Dove-TV launch Design ",
      },
      {
        id: 5,
        url: Dove5,
        altText: "Dove-Outside  ",
      },
      {
        id: 6,
        url: Dove6,
        altText: "Dove-Kitchen Design ",
      },
      {
        id: 7,
        url: Dove7,
        altText: "Dove-Sitting Room ",
      },
      {
        id: 8,
        url: Dove8,
        altText: "Dove-Kitchen Design ",
      },
      {
        id: 9,
        url: Dove9,
        altText: "Dove-Washroom Design ",
      },
      {
        id: 10,
        url: Dove10,
        altText: "Dove-Sitting Area ",
      },
      {
        id: 11,
        url: Dove11,
        altText: "Dove-Bedroom Design ",
      },
      {
        id: 12,
        url: Dove12,
        altText: "Dove-Bathroom Design",
      },
    ],
  },

  {
    id: 0.3,
    name: "Ely",
    mainImg: Ely4,
    altText: "Ely",
    images: [
      {
        id: 1,
        url: Ely4,
        altText: "Ely-Front View",
      },
      {
        id: 2,
        url: Ely2,
        altText: "Ely-Kitchen Design",
      },
      {
        id: 3,
        url: Ely3,
        altText: "Ely-Bedroom Design",
      },
      {
        id: 4,
        url: Ely1,
        altText: "Ely-Kitchen Counter Top ",
      },
      {
        id: 5,
        url: Ely5,
        altText: "Ely-Sitting Area Design",
      },
      {
        id: 6,
        url: Ely6,
        altText: "Ely-Backside Gallery ",
      },
      {
        id: 7,
        url: Ely7,
        altText: "Ely-Side Sitting Area ",
      },
      {
        id: 8,
        url: Ely8,
        altText: "Ely-BackSide View ",
      },
      {
        id: 9,
        url: Ely9,
        altText: "Ely-Indoor Gallery ",
      },
      {
        id: 10,
        url: Ely10,
        altText: "Ely-Bathroom Design ",
      },
    ],
  },

  {
    id: 0.4,
    name: "Thomas",
    mainImg: Thomas1,
    altText: "Thomas",
    images: [
      {
        id: 1,
        url: Thomas1,
        altText: "Thomas-Front View",
      },
      {
        id: 2,
        url: Thomas2,
        altText: "Thomas-TV launch Design",
      },
      {
        id: 3,
        url: Thomas3,
        altText: "Thomas-Bedroom Design",
      },
      {
        id: 4,
        url: Thomas4,
        altText: "Thomas-Bedroom Design Closer View ",
      },
      {
        id: 5,
        url: Thomas5,
        altText: "Thomas-TV launch Design ",
      },
      {
        id: 6,
        url: Thomas6,
        altText: "Thomas-Bedroom Design ",
      },
      {
        id: 7,
        url: Thomas7,
        altText: "Thomas-Outside View of Gallery ",
      },
      {
        id: 8,
        url: Thomas8,
        altText: "Thomas-Bathroom Design ",
      },
      {
        id: 9,
        url: Thomas9,
        altText: "Thomas-Inner Design with TV Lanuch ",
      },
      {
        id: 10,
        url: Thomas10,
        altText: "Thomas-Inner Decorations, shelves and sitting area. ",
      },
      {
        id: 11,
        url: Thomas11,
        altText: "Thomas-Kitchen Counter Top Design. ",
      },
      {
        id: 12,
        url: Thomas12,
        altText: "Thomas-Stairs and Decor. ",
      },
      {
        id: 13,
        url: Thomas13,
        altText: "Thomas-TV Lanuch. ",
      },
      {
        id: 14,
        url: Thomas14,
        altText: "Thomas-Study Area Design and Decor. ",
      },
      {
        id: 15,
        url: Thomas15,
        altText: "Thomas-Bedroom with Sliding Window Design. ",
      },
    ],
  },
  {
    id: 0.5,
    name: "Anne",
    mainImg:Anne1,
    altText: "Anne ",
    images: [
      
        {
          id: 1,
          url: Anne1,
          altText: "Anne front View",
        },
        {
          id: 2,
          url: Anne2,
          altText: "Anne kitchen",
        },
        {
          id: 3,
          url: Anne3,
          altText: "Anne central sitting",
        },
        {
          id: 4,
          url: Anne4,
          altText: "dining table",
        },
        {
          id: 5,
          url: Anne5,
          altText: "Anne sofa room",
        },
        {
          id: 6,
          url: Anne6,
          altText: "Anne bedroom",
        },
        {
          id: 7,
          url: Anne7,
          altText: "Anne laundry",
        },
        {
          id: 8,
          url: Anne8,
          altText: "Anne washroom",
        },
        {
          id: 9,
          url: Anne9,
          altText: "Anne washroom",
        },
        {
          id: 10,
          url: Anne10,
          altText: "Anne lawn",
        },
        {
          id: 11,
          url: Anne11,
          altText: "Anne lawn",
        },
        {
          id: 12,
          url: Anne12,
          altText: "Anne bedroom",
        },
        {
          id: 13,
          url: Anne13,
          altText: "bedroom ",
        },

      ],
    },
    {
      id: 0.5,
      name: "sherwood",
      mainImg: sherwood1,
      altText: "Sherwood",
      images: [
        {
          id: 1,
          url: sherwood1,
          altText: "Sherwood front view",
        },
        {
          id: 2,
          url: sherwood2,
          altText: "Sherwood washroom",
        },
        {
          id: 3,
          url: sherwood3,
          altText: "Sherwood kitchen",
        },
        {
          id: 4,
          url: sherwood4,
          altText: "Sherwood room",
        },
        {
          id: 5,
          url: sherwood5,
          altText: "Sherwood room",
        },
        {
          id: 6,
          url: sherwood6,
          altText: "Sherwood room",
        },
        {
          id: 7,
          url: sherwood7,
          altText: "Sherwood bedroom",
        },
        {
          id: 8,
          url: sherwood8,
          altText: "Sherwood living area",
        },
        {
          id: 9,
          url: sherwood9,
          altText: "Sherwood hallway",
        },
        {
          id: 10,
          url: sherwood10,
          altText: "Sherwood dining area",
        },
        {
          id: 11,
          url: sherwood11,
          altText: "Sherwood patio",
        },
        {
          id: 12,
          url: sherwood12,
          altText: "Sherwood backyard",
        },
        {
          id: 13,
          url: sherwood13,
          altText: "Sherwood balcony",
        },
        {
          id: 14,
          url: sherwood14,
          altText: "Sherwood basement",
        },
        {
          id: 15,
          url: sherwood15,
          altText: "Sherwood exterior",
        },
        {
          id: 16,
          url: sherwood16,
          altText: "Sherwood roof view",
        },
        {
          id: 17,
          url: sherwood17,
          altText: "Sherwood office",
        },
        {
          id: 18,
          url: sherwood18,
          altText: "Sherwood garage",
        },
        {
          id: 19,
          url: sherwood19,
          altText: "Sherwood garden",
        },
      ],
    },
    {
      id: 0.6,
      name: "Seaforth",
      mainImg:seaforth1,
      altText: "Seaforth",
      images: [
          {
            id: 1,
            url: seaforth1,
            altText: "seaforth front View",
          },
          {
            id: 2,
            url: seaforth2,
            altText: "seaforth washroo,",
          },
          {
            id: 3,
            url: seaforth3,
            altText: "seaforth kitchen",
          },
          {
            id: 4,
            url: seaforth4,
            altText: "seaforth room",
          },
          {
            id: 5,
            url: seaforth5,
            altText: "seaforth room",
          },
          {
            id: 6,
            url: seaforth6,
            altText: "seaforth room",
          },
          {
            id: 7,
            url: seaforth7,
            altText: "seaforth room",
          },
          {
            id: 8,
            url: seaforth8,
            altText: "seaforth room",
          },
          {
            id: 9,
            url: seaforth9,
            altText: "seaforth room",
          },
          {
            id: 10,
            url: seaforth10,
            altText: "seaforth room",
          },
          {
            id: 11,
            url: seaforth11,
            altText: "seaforth room",
          },

    ]},
    {
      id: 0.7,
      name: "Atkinson",
      mainImg:Atkinson1,
      altText: "Atkinson",
      images: [
        
          {
            id: 1,
            url: Atkinson1,
            altText: "Atkinson front View",
          },
          {
            id: 2,
            url: Atkinson2,
            altText: "Atkinson kitchen,",
          },
          {
            id: 3,
            url: Atkinson3,
            altText: "Atkinson room",
          },
          {
            id: 4,
            url: Atkinson4,
            altText: "Atkinson room",
          },
          {
            id: 5,
            url: Atkinson5,
            altText: "Atkinson lawn",
          },
          {
            id: 6,
            url: Atkinson6,
            altText: "Atkinson lawn backside",
          },


    ]},
    {
      id: 0.8,
      name: "Wallis",
      mainImg:Wallis1,
      altText: "Wallis",
      images: [
          {
            id: 1,
            url: Wallis1,
            altText: "Wallis front View",
          },
          {
            id: 2,
            url: Wallis2,
            altText: "Wallis room,",
          },
          {
            id: 3,
            url: Wallis3,
            altText: "Wallis kitchen",
          },
          {
            id: 4,
            url: Wallis4,
            altText: "Wallis outside",
          },
          {
            id: 5,
            url: Wallis5,
            altText: "Wallis room",
          },
          {
            id: 6,
            url: Wallis6,
            altText: "Wallis room",
          },
          {
            id: 7,
            url: Wallis7,
            altText: " lawnWallis washroom",
          },
          {
            id: 8,
            url: Wallis8,
            altText: "Wallis lawn ",
          },
    ]},
    {
      id: 0.8,
      name: "Ellesmere",
      mainImg:Ellesmere2,
      altText: "Ellesmere",
      images: [
          {
            id: 1,
            url: Ellesmere1,
            altText: "Ellesmere front View",
          },
          {
            id: 2,
            url: Ellesmere2,
            altText: "Ellesmere sitting",
          },
          {
            id: 3,
            url: Ellesmere3,
            altText: "Ellesmere kitchen",
          },
          {
            id: 4,
            url: Ellesmere4,
            altText: "Ellesmere room",
          },
          {
            id: 5,
            url: Ellesmere5,
            altText: "Ellesmere launge",
          },
          {
            id: 6,
            url: Ellesmere6,
            altText: "Ellesmere lawn",
          },
          {
            id: 7,
            url: Ellesmere7,
            altText: " Ellesmere washroom",
          },
          {
            id: 8,
            url: Ellesmere8,
            altText: "Ellesmere bedroom",
          },
{
            id: 9,
            url: Ellesmere9,
            altText: "Ellesmere mirror",
          },
{
            id: 10,
            url: Ellesmere10,
            altText: "Ellesmere sitting",
          },



    ]},               
    {
      id: 0.9,
      name: "Park Road",
      mainImg:ParkRoad1,
      altText: "Park Road",
      images: [
          {
            id: 1,
            url: ParkRoad1,
            altText: "ParkRoad front View",
          },
          {
            id: 2,
            url: ParkRoad2,
            altText: "ParkRoad sitting",
          },
          {
            id: 3,
            url: ParkRoad3,
            altText: "ParkRoad kitchen",
          },
          {
            id: 4,
            url: ParkRoad4,
            altText: "ParkRoad room",
          },
          {
            id: 5,
            url: ParkRoad5,
            altText: "ParkRoad launge",
          },
          {
            id: 6,
            url: ParkRoad6,
            altText: "ParkRoad lawn",
          },
          {
            id: 7,
            url: ParkRoad7,
            altText: " ParkRoad washroom",
          },
          {
            id: 8,
            url: ParkRoad8,
            altText: "ParkRoad bedroom",
          },
          {
            id: 9,
            url: ParkRoad9,
            altText: "ParkRoad mirror",
          },
          {
            id: 10,
            url: ParkRoad10,
            altText: "ParkRoad sitting",
          },
          {
            id: 11,
            url: ParkRoad11,
            altText: "ParkRoad sitting",
          },
          {
            id: 12,
            url: ParkRoad12,
            altText: "ParkRoad sitting",
          },
          {
            id: 13,
            url: ParkRoad13,
            altText: "ParkRoad sitting",
          },
          {
            id: 14,
            url: ParkRoad14,
            altText: "ParkRoad sitting",
          },
          {
            id: 15,
            url: ParkRoad15,
            altText: "ParkRoad sitting",
          },
          {
            id: 16,
            url: ParkRoad16,
            altText: "ParkRoad sitting",
          },
          {
            id: 17,
            url: ParkRoad17,
            altText: "ParkRoad sitting",
          },
          {
            id: 18,
            url: ParkRoad18,
            altText: "ParkRoad sitting",
          },
        ]},
        {
          id: 0.10,
          name: "Picnic Point Road",
          mainImg:PicnicPointRoad1,
          altText: "Picnic Point Road",
          images: [
            {
              id: 1,
              url: PicnicPointRoad1,
              altText: "Picnic Point Road front View",
            },
            {
              id: 2,
              url: PicnicPointRoad2,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 3,
              url: PicnicPointRoad3,
              altText: "PicnicPointRoad kitchen",
            },
            {
              id: 4,
              url: PicnicPointRoad4,
              altText: "PicnicPointRoad room",
            },
            {
              id: 5,
              url: PicnicPointRoad5,
              altText: "PicnicPointRoad launge",
            },
            {
              id: 6,
              url: PicnicPointRoad6,
              altText: "PicnicPointRoad lawn",
            },
            {
              id: 7,
              url: PicnicPointRoad7,
              altText: " PicnicPointRoad washroom",
            },
            {
              id: 8,
              url: PicnicPointRoad8,
              altText: "PicnicPointRoad bedroom",
            },
            {
              id: 9,
              url: PicnicPointRoad9,
              altText: "PicnicPointRoad mirror",
            },
            {
              id: 10,
              url: PicnicPointRoad10,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 11,
              url: PicnicPointRoad11,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 12,
              url: PicnicPointRoad12,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 13,
              url: PicnicPointRoad13,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 14,
              url: PicnicPointRoad14,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 15,
              url: PicnicPointRoad15,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 16,
              url: PicnicPointRoad16,
              altText: "PicnicPointRoad sitting",
            },
          {
              id: 17,
              url: PicnicPointRoad17,
              altText: "PicnicPointRoad sitting",
            },
          {
              id: 18,
              url: PicnicPointRoad18,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 19,
              url: PicnicPointRoad19,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 20,
              url: PicnicPointRoad20,
              altText: "PicnicPointRoad sitting",
            },

            {
              id: 21,
              url: PicnicPointRoad21,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 22,
              url: PicnicPointRoad22,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 23,
              url: PicnicPointRoad23,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 24,
              url: PicnicPointRoad24,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 25,
              url: PicnicPointRoad25,
              altText: "PicnicPointRoad sitting",
            },
            {
              id: 26,
              url: PicnicPointRoad26,
              altText: "PicnicPointRoad sitting",
            },
        ]},
        {
          id: 1.5,
          name: "Gwandalan",
          mainImg: Gwandalan1,
          altText: "Gwandalan",
          images: [
            {
              id: 1,
              url: Gwandalan1,
              altText: "Gwandalan front view",
            },
            {
              id: 2,
              url: Gwandalan2,
              altText: "Gwandalan washroom",
            },
            {
              id: 3,
              url: Gwandalan3,
              altText: "Gwandalan kitchen",
            },
            {
              id: 4,
              url: Gwandalan4,
              altText: "Gwandalan room",
            },
            {
              id: 5,
              url: Gwandalan5,
              altText: "Gwandalan room",
            },
            {
              id: 6,
              url: Gwandalan6,
              altText: "Gwandalan room",
            },
            {
              id: 7,
              url: Gwandalan7,
              altText: "Gwandalan bedroom",
            },
            {
              id: 8,
              url: Gwandalan8,
              altText: "Gwandalan living area",
            },
            {
              id: 9,
              url: Gwandalan9,
              altText: "Gwandalan hallway",
            },
            {
              id: 10,
              url: Gwandalan10,
              altText: "Gwandalan dining area",
            },
            {
              id: 11,
              url: Gwandalan11,
              altText: "Gwandalan patio",
            },
            {
              id: 12,
              url: Gwandalan12,
              altText: "Gwandalan backyard",
            },
            {
              id: 13,
              url: Gwandalan13,
              altText: "Gwandalan balcony",
            },
            {
              id: 14,
              url: Gwandalan14,
              altText: "Gwandalan basement",
            },
            {
              id: 15,
              url: Gwandalan15,
              altText: "Gwandalan exterior",
            },
            {
              id: 16,
              url: Gwandalan16,
              altText: "Gwandalan roof view",
            },
            {
              id: 17,
              url: Gwandalan17,
              altText: "Gwandalan office",
            },
            {
              id: 18,
              url: Gwandalan18,
              altText: "Gwandalan garage",
            },
            {
              id: 19,
              url: Gwandalan19,
              altText: "Gwandalan garden",
            },
          ],
        },
        {
          id: 2.5,
          name: "Barrow",
          mainImg: Barrow1,
          altText: "Barrow",
          images: [
            {
              id: 1,
              url: Barrow1,
              altText: "Barrow front view",
            },
            {
              id: 2,
              url: Barrow2,
              altText: "Barrow washroom",
            },
            {
              id: 3,
              url: Barrow3,
              altText: "Barrow kitchen",
            },
            {
              id: 4,
              url: Barrow4,
              altText: "Barrow room",
            },
            {
              id: 5,
              url: Barrow5,
              altText: "Barrow room",
            },
            {
              id: 6,
              url: Barrow6,
              altText: "Barrow room",
            },
            {
              id: 7,
              url: Barrow7,
              altText: "Barrow bedroom",
            },
            {
              id: 8,
              url: Barrow8,
              altText: "Barrow living area",
            },
            {
              id: 9,
              url: Barrow9,
              altText: "Barrow hallway",
            },
            {
              id: 10,
              url: Barrow10,
              altText: "Barrow dining area",
            },
            {
              id: 11,
              url: Barrow11,
              altText: "Barrow patio",
            },
            {
              id: 12,
              url: Barrow12,
              altText: "Barrow backyard",
            },
            {
              id: 13,
              url: Barrow13,
              altText: "Barrow balcony",
            },
            {
              id: 14,
              url: Barrow14,
              altText: "Barrow basement",
            },
            {
              id: 15,
              url: Barrow15,
              altText: "Barrow exterior",
            },
            {
              id: 16,
              url: Barrow16,
              altText: "Barrow roof view",
            },
            {
              id: 17,
              url: Barrow17,
              altText: "Barrow office",
            },
            {
              id: 18,
              url: Barrow18,
              altText: "Barrow garage",
            },
            {
              id: 19,
              url: Barrow19,
              altText: "Barrow garden",
            },
            {
              id: 20,
              url: Barrow20,
              altText: "Barrow pool area",
            },
            {
              id: 21,
              url: Barrow21,
              altText: "Barrow guest room",
            },
            {
              id: 22,
              url: Barrow22,
              altText: "Barrow terrace",
            },
            {
              id: 23,
              url: Barrow23,
              altText: "Barrow study room",
            },
            {
              id: 24,
              url: Barrow24,
              altText: "Barrow gym",
            },
            {
              id: 25,
              url: Barrow25,
              altText: "Barrow media room",
            },
            {
              id: 26,
              url: Barrow26,
              altText: "Barrow nursery",
            },
            {
              id: 27,
              url: Barrow27,
              altText: "Barrow wine cellar",
            },
            {
              id: 28,
              url: Barrow28,
              altText: "Barrow library",
            },
            {
              id: 29,
              url: Barrow29,
              altText: "Barrow spa",
            },
            {
              id: 30,
              url: Barrow30,
              altText: "Barrow laundry room",
            },
          ],
        },
        
      ]
