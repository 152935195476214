import React from "react";
import { Player } from "@lottiefiles/react-lottie-player"; // Import Lottie Player
import aboutanimation from "../../assets/gifs/aboutbg.json"; // Update path as necessary

// Reusable Card Component
const Card = ({ title, description }) => (
  <div
    data-aos="zoom-in-right"
    className="bg-white border rounded-lg items-center shadow-lg p-6 flex flex-col justify- w-full sm:w-80 md:w-96 lg:w-1/4"
  >
    <h4 className="font-bold text-xl mb-2 text-cyan-800">{title}</h4>
    <p className="text-justify">{description}</p>
  </div>
);

const About = () => {
  const cardsData = [
    {
      title: "Quality Service",
      description:
        "We offer quality to our clients and ensure we are there for them throughout the project.",
    },
    {
      title: "Expert Guidance",
      description:
        "Our team of energetic individuals provides consultation on selecting the best site and designs.",
    },
    {
      title: "Timely Execution",
      description:
        "We guarantee the timely handover of the completed project, exactly as visualized by our clients.",
    },
    {
      title: "Creative Designs",
      description:
        "Since day one, we have delivered creative and cozy designs tailored to our clients’ needs.",
    },
    {
      title: "Post-Handover Support",
      description:
        "We don’t stop once the keys are handed over; we also offer warranties and post-handover support.",
    },
  ];

  return (
    <div className="relative lg:h-screen w-full flex flex-col justify-center items-center p-4 pt-24">
      <h3 className="font-semibold text-4xl pb-8 text-cyan-800">ABOUT US</h3>
      <div className="flex flex-wrap justify-center gap-6">
        {cardsData.map((card, index) => (
          <Card key={index} title={card.title} description={card.description} />
        ))}
      </div>

      {/* Lottie Animation at the Bottom */}
      <div className="absolute bottom-0 z-[-1] opacity-40 w-screen">
        <Player
          src={aboutanimation} 
          loop
          autoplay
          className="w-screen h-fit object-cover"
        />
      </div>
    </div>
  );
};

export default About;
