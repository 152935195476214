import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import contactAnimation from "../../assets/gifs/contactus.json"; // Update path as necessary

const Contact = () => {
  return (
    <div className="flex flex-col h-screen w-full items-center p-5 pt-28 bg-gray-100">
      {/* Flex container for Lottie Animation and Contact Information */}
      <div className="flex flex-col md:flex-row items-center justify-center w-full h-full">
        
        {/* Contact Information Section */}
        <div className="w-full md:w-1/2 flex flex-col items-center md:items-start p-4">
          <h3 className="font-semibold text-4xl mb-6 text-cyan-800 text-center md:text-left">CONTACT US</h3>
          <p className="pt-4 pb-10 text-lg text-center md:text-left">
            You can reach us through the following contact methods:
          </p>
          <ul className="flex flex-col w-full max-w-md mx-auto rounded-lg mb-3">
            <li className="mb-3" data-aos="zoom-in-right">
              <a
                href="#"
                className="flex p-4 hover:bg-cyan-50 bg-cyan-200 bg-opacity-10 rounded-lg shadow-md transition duration-200"
              >
                <span className="material-icons md-18">location_on</span>
                <span className="ml-2 truncate">
                  Gwandalan Rd Padstow NSW 2211, Australia
                </span>
              </a>
            </li>
            <li className="mb-3" data-aos="zoom-in-right">
              <a
                href="#"
                className="flex p-4 bg-cyan-200 bg-opacity-10 hover:bg-cyan-50 rounded-lg shadow-md transition duration-200"
              >
                <span className="material-icons md-18">drafts</span>
                <span className="ml-2 truncate">info@arkhomes.com.au</span>
              </a>
            </li>
            <li data-aos="zoom-in-right">
              <a
                href="#"
                className="flex p-4 bg-cyan-200 bg-opacity-10 hover:bg-cyan-50 rounded-lg shadow-md transition duration-200"
              >
                <span className="material-icons md-18">call</span>
                <span className="ml-2 truncate">0466 380 416</span>
              </a>
            </li>
          </ul>
        </div>

        {/* Lottie Animation Section */}
        <div className="w-full md:w-1/2 flex justify-center  p-4">
          <Player
            src={contactAnimation}
            autoplay
            loop
            className="w-full h-auto md:w-80 lg:w-96" // Responsive width for animation
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
