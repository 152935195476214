// components/Navbar.js
import React, {  useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/imgs/logo/logo.png";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation(); // Get the current location

  const handleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  // Determine if the current route matches a specific nav item
  const isActive = (navItem) => {
    const currentPath = location.pathname.toLowerCase();
    return currentPath.includes(navItem.toLowerCase());
  };

  return (
    <nav className="fixed z-10 top-0 w-full bg-neutral-100 py-4 shadow-md flex justify-between items-center px-6">
      <Link to="/" className="flex items-center">
        <img src={logo} style={{ height: "40px", width: "100px" }} alt="Logo" />
      </Link>

      {/* Desktop Menu */}
      <div className="hidden lg:flex space-x-4">
        <Link
          className={`${
            isActive("/") && location.pathname === "/" ? "text-cyan-800 underline font-semibold" : "text-cyan-700 hover:text-cyan-800"
          }`}
          to="/"
        >
          Home
        </Link>
        <Link
          className={`${
            isActive("/about") ? "text-cyan-800 underline font-semibold" : "text-cyan-700 hover:text-cyan-800"
          }`}
          to="/About"
        >
          About Us
        </Link>
        <Link
          className={`${
            isActive("/projects") ? "text-cyan-800 underline font-semibold" : "text-cyan-700 hover:text-cyan-800"
          }`}
          to="/Projects/Ely"
        >
          Our Projects
        </Link>
        <Link
          className={`${
            isActive("/contact") ? "text-cyan-800 underline font-semibold" : "text-cyan-700 hover:text-cyan-800"
          }`}
          to="/Contact"
        >
          Contact Us
        </Link>
      </div>

      {/* Mobile Menu */}
      <button
        className="lg:hidden text-neutral-500 hover:text-neutral-700"
        onClick={handleMenu}
      >
        <span className="material-icons">{toggleMenu ? "close" : "menu"}</span>
      </button>

      {toggleMenu && (
        <div className="absolute top-16 right-0 bg-gray-100 rounded-lg shadow-md p-5 space-y-4">
          <Link
            className={`block ${isActive("/") && location.pathname === "/" ? "text-cyan-800 underline" : ""}`}
            to="/"
          >
            Home
          </Link>
          <Link
            className={`block ${isActive("/about") ? "text-cyan-800 underline" : ""}`}
            to="/About"
          >
            About Us
          </Link>
          <Link
            className={`block ${isActive("/projects") ? "text-cyan-800 underline" : ""}`}
            to="/Projects/Ely"
          >
            Our Projects
          </Link>
          <Link
            className={`block ${isActive("/contact") ? "text-cyan-800 underline" : ""}`}
            to="/Contact"
          >
            Contact Us
          </Link>
        </div>
      )}
    </nav>
  );
}
