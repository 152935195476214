import React, { useEffect } from "react";
import "./App.css";
import {  BrowserRouter , Routes, Route} from "react-router-dom";
import Navbar from "./components/Navbar/index";
import Aos from "aos";
import { Home, About, Contact, ProjectDetails } from "../src/pages/index";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-out",
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Projects/:name" element={<ProjectDetails />} />
          {/* <Route path="/Projects" element={<ProjectDetails />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
window.addEventListener('load', Aos.refresh)
