import React, { useState } from "react";
// import image from "../../assets/imgs/Projects/Hinemoa/1.jpeg";
import { projects } from "../../dataprovider/data";
import { Link, useParams } from "react-router-dom";
import "./index.css";
export default function Index() {
  const { name } = useParams();
  console.log(name);
  const project = projects.find((p) => p.name === name);
  const [activeProject, setActiveProject] = useState(project);

  const handleProjectClick = (project) => {
    setActiveProject(project);
  };
  return (
    <>
      <section className="projects pt-28" data-aos="zoom-in-right">
        {/* =====================Start Breadcrumbs=================== */}
        <div className="flex justify-center pl-16 py-5 overflow-x-auto hide-scroll-bar">
          <div className="flex gap-4 px-4 items-center">
            {projects.map((project) => (
              <Link
                key={project.name}
                to={`/Projects/${project.name}`}
                onClick={() => handleProjectClick(project)}
                className={`p-2 text-base font-semibold h-fit w-fit items-center whitespace-nowrap text-center shadow rounded-md border transition-all duration-300 ease-in-out
                  ${
                    activeProject === project
                      ? "bg-gradient-to-r from-cyan-700 to-black text-white border-transparent"
                      : "bg-white text-black border-gray-300"
                  } 
                  hover:bg-gradient-to-r hover:from-cyan-700  hover:to-cyan-800 
                  hover:text-white hover:border-transparent`}
              >
                {project.name}
              </Link>
            ))}
          </div>
        </div>

        {/* =====================End Breadcrumbs================ */}
        <div className="">
          <div className="text-center md:text-2xl text-xl text-cyan-800 tracking-wide uppercase  mt-5">
            {`${name} Project Gallery`} <br></br>{" "}
            <span className="decor"></span>
          </div>
          <div className="items-center px-5" data-aos="fade-right">
            {project.images.map(
              (image) =>
                image.url && (
                  <img
                    data-aos="fade-left"
                    style={{
                      display: "block",
                      margin: "0 auto",
                      textAlign: "center",
                      marginTop: "2rem",
                      borderRadius: "20px",
                      height: "100%",
                      maxWidth: "65%",
                    }}
                    className="object-contain mt-3 "
                    key={image.id}
                    src={image.url}
                    alt={image.altText}
                  />
                )
            )}
          </div>
        </div>
        <br/>
        <hr/>
      </section>
      <footer className="bg-neutral-200 text-center dark:bg-neutral-700 lg:text-left">
        <div className="p-2 text-center text-neutral-700 dark:text-neutral-200">
          © 2023 Copyright |
          <a
            className="text-neutral-800 dark:text-neutral-400 p-2"
            href="https://arkhomes.com.au/"
          >
            arkhomes | ALL Rights Reserved{" "}
          </a>
        </div>
      </footer>
    </>
  );
}
