import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import{ projects } from "../../dataprovider/data";
import Aos from "aos";
import "aos/dist/aos.css";
export default function Home() {
  let blue = "#0C2D48";
  const [bgColor, setBgColor] = useState(blue);
  const [toggleList,setToggleList]=useState(false);
  let targetDiv = "";
  const [scrolling, setScrolling] = useState(false); 

  const handleopenprojects = () => {
  setToggleList(!toggleList);
  };

  const handleThemeColor = (color) => {
    setBgColor(color);
  };
  
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);   
    targetDiv = document.getElementById("hidden");
    let img = document.getElementsByTagName("img");
    let text = document.getElementById("project-name");
    
    for (let i = 0; i < img.length; i++) {
      img[i].addEventListener("mouseover", function () {
        let alt = this.alt;
        text.textContent = alt;
      });
      img[i].addEventListener("mouseleave", function () {
        let alt = this.alt;
        text.textContent = "";
      });
    }
    filterSelection("all");
   
  });
 
  const filterSelection = (c) => {
    let x, i;
    x = document.getElementsByClassName("column");
   
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
  
      w3RemoveClass(x[i], "show");
      //check if class is present
      if (x[i].className.indexOf(c) > -1) w3AddClass(x[i], "show");
    }
  };

  const w3AddClass = (element, name) => {
   
    let i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
 
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) {
        element.className += " " + arr2[i];
      }
    }
  };

  const w3RemoveClass = (element, name) => {
    let i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);
      }
    }
    element.className = arr1.join(" ");
  };

  const handleKeyDown = (e) => {
  
    if (e.key === "ArrowRight") {
      const sliderContainer = document.querySelector(".overflow-x-scroll");
      sliderContainer.scrollLeft += 20; 
    }
    if (e.key === "ArrowLeft") {
      const sliderContainer = document.querySelector(".overflow-x-scroll");
      sliderContainer.scrollLeft -= 20; // change 50 to your desired amount
    }
  };

  const scrollRef = useRef(null);

  const handleScroll = (e) => {
    setScrolling(true);
    if (scrollRef.current) {
      e.preventDefault(); // Prevent default vertical scroll
      scrollRef.current.scrollLeft += e.deltaY; // Use deltaY to scroll horizontally
      setScrolling(scrollRef.current.scrollLeft > 0); // Set scrolling true if scrolled right
    }
  };

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      container.addEventListener('wheel', handleScroll);
      return () => container.removeEventListener('wheel', handleScroll); // Cleanup listener
    }
  }, []);

  useEffect(() => {
    const handleScrollEvent = () => {
      if (window.scrollX > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);

  return (
    <>
      <section
        className=" p-6 pb-20 pt-24"
        style={{ backgroundColor: bgColor }}
      >
      <div className={`flex items-center transition-all duration-300 ${scrolling ? "justify-between" : "justify-start"}`}>
        <div className="flex flex-col relative p-5">
          {!scrolling && (
            <div className="rounded-lg hidden lg:flex bg-gray-700 lg:w-44 px-3 py-1">
              <h2 className="text-white">Scroll to explore</h2>
              <span className="animate_it mt-2 p-1 ml-2" />
            </div>
          )}
        </div>
        <div className={`flex flex-col transition-width lg:px-5 duration-300 w-full ${scrolling ? "lg:w-full" : "lg:w-3/4"}`}>
          <div className="mt-2" data-aos="zoom-in">
            <div className="flex flex-col m-auto">
              <div
                className="flex overflow-x-scroll pb-10 hide-scroll-bar animate-slide-in"
                ref={scrollRef}
              >
                <div className="flex flex-nowrap">
                  {projects.map((project) => (
                    <div
                      key={project.id}
                      className={`project column ${project.name} w-44 px-7`}
                      data-aos="zoom-in"
                    >
                      <Link to={`/Projects/${project.name}`} state={{ activeProject: project }}>
                        <img
                          className="max-w-none object-cover cursor-pointer"
                          src={project.mainImg}
                          alt={project.altText}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center w-50 h-0.5 ">
            <div id="project-name" className=" font-medium text-white"/>
          </div>
        </div>
      </div>
      <div className="backgroundSelector bg-white py-2 px-4 rounded-lg shadow-md flex items-center space-x-4">
        <div
          className="dot h-10 w-10 rounded-full cursor-pointer transition-transform transform hover:scale-110"
          onClick={() => handleThemeColor("#1A4314")}
          style={{ backgroundColor: "#1A4314" }}
        />
        <div
          className="dot h-10 w-10 rounded-full cursor-pointer transition-transform transform hover:scale-110"
          onClick={() => handleThemeColor("#0C2D48")}
          style={{ backgroundColor: "#0C2D48" }}
        />
        <div
          className="dot h-10 w-10 rounded-full cursor-pointer transition-transform transform hover:scale-110"
          onClick={() => handleThemeColor("#000000d9")}
          style={{ backgroundColor: "#000000d9" }}
        />
      </div>
       {/* <div className="text-center ml-4 md:w-64 md:flex w-40 mt-8   animate__animated animate__fadeInRight ">
          <button
            className="font-medium text-white uppercase"
            onClick={handleopenprojects}
          >
           Project Explorer
          </button>
          {toggleList &&
          <div className="filter__projects text-center w-10  animate__animated animate__fadeInRight">
            <div className="filterlist " >
              <div id="myBtnContainer">
                <ul className="text-white font-light px-2 text-center md:flex ">
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("all")}
                    >
                      <span className="filterlink ">
                        All<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Hinemoa")}
                    >
                      <span className="filterlink ">
                        Hinemoa<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Dove")}
                    >
                      <span className="filterlink ">
                        Dove<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Thomas")}
                    >
                      <span className="filterlink ">
                        Thomas<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Ely")}
                    >
                      <span className="filterlink ">
                        Ely<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>}
        </div>*/}
      </section>
    </>
  );
}
